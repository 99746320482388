@import '../../../assets/style/variables';

.authWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;

    .resetPassword {
        display: flex;
        align-items: center;
        justify-content: space-between;

        font-family: $primary-font-family;
        max-width: 825px;
        margin: auto;
        width: 100%;

        .illustration {
            margin-right: 30px;

            img {
                width: 306px;
            }
        }

        .content {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: flex-start;

            .logoContainer {
                margin: 40px 0;

                .logo {
                    width: 116px;
                }
            }

            .container {
                border: 1px solid transparent;
                padding: 50px 48px 42px;
                border-radius: 20px;
                background: $default-light-background;

                .title {
                    color: $primary-color;
                    margin-bottom: 38px;
                }

                .label {
                    color: $primary-text-color;
                    line-height: 1rem;
                }
            }

            .options {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-evenly;

                margin: 24px 0 0;
                width: 100%;

                span {
                    font-family: $primary-font-family;
                    font-style: normal;
                    line-height: 27px;
                    font-weight: 700;
                    font-size: 13px;
                    color: $primary-color;
                }
            }
            :global(.MuiInput-root) {
                border-color: $primary-border-color;
            }
            :global(.MuiInput-root),
            :global(.MuiButton-root) {
                height: 40px;
                min-height: 40px;
                border-radius: 8px;
            }
        }
    }
}

@media screen and (max-width: 840px) {
    .resetPassword {
        flex-direction: column-reverse;
        align-items: center;
    }

    .illustration {
        margin: 40px 0 24px 0;
    }

    .content {
        max-width: 375px;
        width: 100%;

        .container {
            padding: 50px 16px;
            width: 100%;

            h2 {
                font-size: 24px;
            }
        }
    }
}
