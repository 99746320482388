@import '../../../assets/style/variables';

.authWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.login {
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-family: $primary-font-family;
    max-width: 825px;
    margin: auto;
    width: 100%;
}

.illustration {
    margin-right: 30px;

    img {
        width: 350px;
    }
}

.content {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;

    .logoContainer {
        margin-bottom: 40px;
        margin-top: 40px;

        .logo {
            width: 116px;
        }
    }

    .container {
        border: 1px solid transparent;
        padding: 50px 48px 42px;
        background: $default-light-background;
        border-radius: 20px;

        .title {
            color: $primary-color;
            margin-bottom: 24px;
        }

        .label {
            line-height: 1rem;
        }

        :global(.MuiInput-root) {
            border-color: $primary-border-color;
        }
        :global(.MuiInput-root),
        :global(.MuiButton-root) {
            height: 40px;
            min-height: 40px;
            border-radius: 8px;
        }
    }
}

@media screen and (max-width: 840px) {
    .login {
        flex-direction: column-reverse;
    }

    .illustration {
        margin: 40px 0 24px 0;
    }

    .content {
        max-width: 375px;
        width: 100%;

        .container {
            padding: 50px 16px;
            width: 100%;

            h2 {
                font-size: 24px;
            }
        }
    }
}

.primary {
    color: $primary-color;
}
